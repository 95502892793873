//In src/components/sub-form.component.js
import React, { useState } from 'react';
// import Button from "../button/button.component";

// const SubscriptionForm = () => {
//     return (
//         <script async data-uid="01c1e7cb80" src="https://marvelous-producer-5967.ck.page/01c1e7cb80/index.js"></script>
//     )
// }

const SubscriptionForm = () => {
    const [status, setStatus] = useState(null);
    const [email, setEmail] = useState('');

    //FORM_URL should be the same as the form action url pointed out above
    const FORM_URL = `https://app.convertkit.com/forms/1925607/subscriptions`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        try {
            const response = await fetch(
                FORM_URL,
                {
                    method: 'post',
                    body: data,
                    headers: {
                        accept: 'application/json',
                    },
                }
            );
            setEmail('');
            const json = await response.json();
            if (json.status === 'success') {
                setStatus('SUCCESS');
                window.location.href = '/subscribe-success/?c=1'
                return;
            }
        } catch (err) {
            setStatus('ERROR');
            console.log(err);
        }
    };

    const handleInputChange = event => {
        const {value} = event.target;
        setEmail(value);
    }

    return (
        <div className="sub">
            <p>Drop your email if you'd like to get updates on new writings or analysis tools available on this site.</p>
            <p>I respect your privacy. I won't spam you, or give your email address away to anyone. Unsubscribe at any time.</p>

            {status === 'ERROR' ? (
                <p style={{color: '#e74c3c', fontWeight: 'bold'}}>Oops, Something went wrong! try again.</p>
            ) : ''}

            <form
                className="sub__form"
                action={FORM_URL}
                method="post"
                onSubmit={handleSubmit}
            >
                <label>Email Address</label><br/>
                <input
                    type="email"
                    aria-label="Your email"
                   //The name attribute should be the same as on you selected form.
                    name="email_address"
                    placeholder="Your email address"
                    onChange={handleInputChange}
                    value={email}
                    style={{fontSize: '14px', padding: '5px', minWidth: '300px', maxWidth: '100%', borderRadius: '3px'}}
                    required
                />
                <br/><br/>
                <label>What interests you about subscribing to this data blog?</label><br/>
                <textarea
                    // className="formkit-input"
                    name="fields[reason_for_subscribing]"
                    type="text"
                    placeholder="What would you like for me to write about?"
                    style={{fontSize: '14px', padding: '5px', minWidth: '300px', maxWidth: '100%', borderRadius: '3px'}}
                />
                <br/>

                <button type="submit">
                    Subscribe
                </button>
            </form>
        </div>
    );
};

export default SubscriptionForm