import React from 'react'
import { Link } from 'gatsby'

import { Layout, SEO } from '../components/common'
import SubscriptionForm from '../components/subscribeform'
// import { MetaData } from '../components/common/meta'

const SubscribePage = () => (
    <React.Fragment>
        <SEO title='Subscribe' description='Subscribe to new blog posts or analysis tools' />
        <Layout>
            <div className="container">
                <article className="content">
                    <h1 className="content-title">Stay in the loop</h1>

                    <SubscriptionForm />

                    {/*
                    <p>Drop your email if you'd like to get updates on new writings or new analysis tools available on this site.</p>
                    <p>I won't spam you, or give your email address away to anyone.</p>
                    <section className="content-body">
                        <form
                            name="subscribe" method="POST"
                            data-netlify="true"
                            action="/subscribe-success/"
                            enctype="multipart/form-data"
                        >
                          <input type="hidden" name="form-name" value="subscribe" />
                          <p>
                            <label>Email Address
                            <br/><input type="email" name="email" required/></label>
                          </p>
                          <p>
                            <label>What interests you about subscribing to this data blog? What would you like for me to write about?
                            <br/><textarea name="message"></textarea></label>
                          </p>
                          <div data-netlify-recaptcha="true"></div>
                          <p>
                            <button type="submit">Send</button>
                          </p>
                        </form>
                    </section>

                    */}
                </article>
            </div>
        </Layout>
    </React.Fragment>
)

export default SubscribePage
